import { defineStore } from "pinia";
import { CheckoutData, ICard, Rate } from "@/types/api/cart.types";
import { paymentMethods } from "~/configs";
import { useGeneralStore } from "~/stores/general";

interface StripeI {
  source: any;
  cardElement?: any;
  cardNumberElement?: any;
  cardExpiryElement?: any;
  cardCvcElement?: any;
  paymentIntentClientSecret?: string;
}

interface State {
  stripe: StripeI;
  buttonLoading: boolean;
  generalForm: (CheckoutData & { payment_method?: string | null }) | null;
  paymentMethodCard: ICard | null;
  paymentMethodId: number | string | null;
  firstStepShippingMethod: string | null;
  firstStepShippingMethods: Rate[];
  removingIdsLoading: number[];
  isCartOpen: boolean;
}
export const useCheckoutStore = defineStore("checkout", {
  state: (): State => ({
    stripe: { source: null, paymentIntentClientSecret: "" },
    buttonLoading: false,
    generalForm: null,
    paymentMethodCard: null,
    paymentMethodId: null,
    firstStepShippingMethod: null,
    firstStepShippingMethods: [],
    removingIdsLoading: [],
    isCartOpen: false,
  }),
  getters: {
    stripeCardOptions() {
      const generalStore = useGeneralStore();
      return generalStore.paymentMethodsData?.find(
        (el) => el.code === paymentMethods.stripe && !el.express,
      );
    },
    paypalExpressOptions() {
      const generalStore = useGeneralStore();
      return generalStore.paymentMethodsData?.find(
        (el) => el.code === paymentMethods.paypal_express && el.express,
      );
    },
    paypalOptions() {
      const generalStore = useGeneralStore();
      return generalStore.paymentMethodsData?.find(
        (el) => el.code === paymentMethods.paypal && !el.express,
      );
    },
    stripePaypalOptions() {
      const generalStore = useGeneralStore();
      return generalStore.paymentMethodsData?.find(
        (el) => el.code === paymentMethods.paypal && el.express,
      );
    },
    hideAffirm() {
      const generalStore = useGeneralStore();
      return Number(generalStore?.cart?.final_amount) < 50;
    },
    affirmOptions() {
      const generalStore = useGeneralStore();
      return generalStore.paymentMethodsData?.find(
        (el) => el.code === paymentMethods.affirm && !el.express,
      );
    },
    hideKlarna() {
      const generalStore = useGeneralStore();
      return Number(generalStore?.cart?.final_amount) < 10;
    },
    klarnaOptions() {
      const generalStore = useGeneralStore();
      return generalStore.paymentMethodsData?.find(
        (el) => el.code === paymentMethods.klarna && !el.express,
      );
    },
    hidePayTomorrow() {
      const generalStore = useGeneralStore();
      return (
        Number(generalStore?.cart?.final_amount) < 300 ||
        Number(generalStore?.cart?.final_amount) > 30000
      );
    },
    payTomorrowOptions() {
      const generalStore = useGeneralStore();
      return generalStore.paymentMethodsData?.find(
        (el) => el.code === paymentMethods.paytomorrow && !el.express,
      );
    },
    googlePayOptions() {
      const generalStore = useGeneralStore();
      return generalStore.paymentMethodsData?.find(
        (el) => el.code === paymentMethods.googlePay,
      );
    },
    applePayOptions() {
      const generalStore = useGeneralStore();
      return generalStore.paymentMethodsData?.find(
        (el) => el.code === paymentMethods.applePay,
      );
    },
    amazonOptions() {
      const generalStore = useGeneralStore();
      return generalStore.paymentMethodsData?.find(
        (el) => el.code === paymentMethods.amazon_pay,
      );
    },
  },
});
